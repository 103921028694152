<template>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
  name: 'SSOLogin',
  components:{
        InfoAlert,
    },
    data() {
        return {
            valid: false,
            info:null,
            showDismissibleAlert: false,
            loading: false,
            decodedUrlDetails: null,
            organisation_id:null
        };
    },
    mounted() {
        document.title = 'SSO Login';
        this.decodeUrlParams();
    },
    methods: {
        decodeUrlParams() {
            const urlDetails = this.$route.query
            this.decodedUrlDetails = urlDetails
        },
        getOrganisationId(){
            axios.post(this.$store.state.api+'getOrganisationIdBySSOId',{sso_id:this.decodedUrlDetails.iapps_plant})
            .then(response=>{
                if(response.data.status==='success'){
                    this.loading=false
                    this.organisation_id=response.data.data.organisation_id
                }else{
                    this.info = response.data.msg
                    this.showDismissibleAlert = true
                    this.loading=false
                    this.$router.push("/")
                }
            }).catch(err=>{
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
                this.$router.push("/")
            })
        },
        login(){
            let payload={
                userID:this.decodedUrlDetails.iapps_user,
                organisation_id:this.organisation_id
            }
            axios.post(this.$store.state.api + "UAMloginThroughSSO", payload)
            .then(response => {
                if (response.data.status == "success") {
                    this.$store.commit("setJWT", response.data.jwt)
                    this.$store.commit("setUser", response.data.user)
                    this.$store.commit("setLoggedIn", true)
                    localStorage.setItem('enture_token',response.data.jwt)
                    this.$store.dispatch("refresh")
                    this.$router.push("/dashboard")
                } else {
                    this.info = response.data.error
                    this.showDismissibleAlert = true
                    this.$router.push("/")
                }
                this.loading = false
            })
            .catch(error => {
                console.log(error)
                this.organisation_id= null
                this.info = error
                this.showDismissibleAlert = true
                this.loading = false
                this.$router.push("/")
            })
        },
    },
    watch:{
        decodedUrlDetails(){
            this.getOrganisationId()
        },
        organisation_id(){
            this.login()
        }
    }
}
</script>
